<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    新增商户
                </div>
            </template>
            <div class="content">
                
                <el-form :model="merchantinfo" ref="merchantinfo" label-width="150px" size="small" :inline="true">
                    <el-card>
                        <template #header>
                            <div style="display:flex;justify-content:flex-start;align-items:center">
                                基本信息
                                <el-tag style="margin-left:20px;">商户名称和联系人电话作为商户端账户的登录账号和密码</el-tag>
                            </div>
                        </template>
                        <div class="content-block">
                            <el-form-item label="联系人姓名:" prop="MerchantName" required :rules="{required:'true',trigger:'blur',message:'请填写联系人姓名'}">
                                <el-input v-model="merchantinfo.MerchantName" ></el-input>
                            </el-form-item>
                            <el-form-item label="联系人电话:" prop="MerchantMobile" required :rules="{required:'true',trigger:'blur',message:'请填写联系人电话'}">
                                <el-input v-model="merchantinfo.MerchantMobile" ></el-input>
                            </el-form-item>
                            <el-form-item label="商户名称:" prop="Merchant" required :rules="{required:'true',trigger:'blur',message:'请填写商户名称'}">
                                <el-input v-model="merchantinfo.Merchant" style="width: 300px"></el-input>
                            </el-form-item>
                            <el-form-item label="结算(%):" prop="Rate" required :rules="{required:'true',trigger:'blur',message:'请填写结算比例'}">
                                <el-input-number v-model="merchantinfo.Rate" :controls="false" :max="100" :min="1"></el-input-number>
                            </el-form-item>
                            <el-form-item label="分账ID:" prop="fenzhang"  :rules="{trigger:'blur',message:'请填写分账ID'}">
                                <el-input v-model="merchantinfo.fenzhang" :controls="false" style="width: 300px" :max="100" :min="1"></el-input>
                            </el-form-item>
                        </div>
                    </el-card>
                    <!--
                    <el-card>
                        <template #header>
                            <div style="display:flex;justify-content:flex-start;align-items:center">
                                美团参数
                            </div>
                        </template>
                        <div class="content-block">
                            <el-form-item label="appkey:" prop="AppKey" required :rules="{required:'true',trigger:'blur',message:'请填写美团AppKey'}">
                                <el-input v-model="merchantinfo.AppKey" ></el-input>
                            </el-form-item>
                            <el-form-item label="appsecret:" prop="AppSecret" required :rules="{required:'true',trigger:'blur',message:'请填写美团AppSecret'}">
                                <el-input v-model="merchantinfo.AppSecret" style="width: 300px"></el-input>
                            </el-form-item>
                            <el-form-item label="session:" prop="Session">
                                <el-input v-model="merchantinfo.Session" style="width: 300px"></el-input>
                            </el-form-item>
                        </div>
                    </el-card>-->
                </el-form>
                <div class="footer">
                    <el-button type="primary" size="small" @click="SubmitForm('merchantinfo')">保存</el-button>
                    <el-button type="warning" size="small" @click="ResetForm('merchantinfo')">重置</el-button>
                    <el-button size="small" @click="Back()">返回</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>
<style scoped>
>>> .el-card__header {
    padding: 10px 20px;
}
.content .el-card {
    margin-bottom: 20px;
}
.radioblock {
    display: flex;
    flex-direction: column;
}
</style>
<script>
import qs from 'qs';
import constant from "@/constant"
export default {
    data(){
        return {
            keyword:'',
            status:'',
            merchantinfo:{
                ID:'',
                MerchantName:'',
                MerchantMobile:'',
                Merchant:'',
                Address:'',
                Rate:99,
                fenzhang:""
            },
        }
    },
    methods:{
        Confirm(index){
            console.log(this.merchantinfo.returninfo);
            for(let i = 0;i<this.merchantinfo.returninfo.length;i++){
                let curobj = this.merchantinfo.returninfo[i];
                if(i != index)
                    curobj.IsDefault = '0';
            }
        },
        ResetForm(name){
            this.$refs[name].resetFields();
            this.merchantinfo.returninfo = [];
            this.merchantinfo.returninfo.push({
                Contact:'',
                Mobile:'',
                ReturnAddress:'',
                sname:'',
                IsDefault:'1',
            });
        },
        SubmitForm(name){
            this.$refs[name].validate((valid)=>{
                if(valid){
                    this.axios.post(constant.savemerchant,qs.stringify(this.merchantinfo),{
                        headers:{
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            this.$message.success("操作成功");
                            this.$router.go(-1);
                        }
                    })
                }
            });
        },
        Back(){
            this.$router.push({path:'/merchantlist',query:{keyword:this.keyword,status:this.status,shopstatus:this.shopstatus}});
        }
    },
    created:function(){
        let id = this.$route.query.id;
        console.log(id);
        this.keyword = this.$route.query.keyword;
        if(id){
            this.axios.get(constant.getmerchant,{
                headers:{
                    "Content-Type":'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.merchantinfo = response.data;
                this.merchantinfo.Rate = response.data.Rate/100;
            });
        }
    }
}
</script>